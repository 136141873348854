import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import ReactTable from 'react-data-table-component';
import SweetAlert from "react-bootstrap-sweetalert";
import { baseURL } from "../../Global/Global";

const ViewCategory = () => {

    const users = useSelector(state => state.auth);
    const app = useSelector(state => state.app);
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(true);
    const [category, setCategory] = useState();
    const [showDelete, setShowDelete] = useState(false)
    const [id, setID] = useState(-1);
    const fetchCategory = () => {
        axios({
            method: 'POST',
            url: `${baseURL}api/fetch-categories`,
            data: { app_id: app.currentApp.id },
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + users.token }
        }).then(res => {

            if (res.data.status === true) {
                setCategory(res.data.data);
                
            }

            setLoader(false)
        }).catch(error => {
            console.log(error);
        });
    }
    useEffect(() => {
        fetchCategory()
    }, []);



    const columns = [
        {
            name: 'Id',
            selector: row => row.Id,
            sortable: true,
            grow: 1,
            wrap: true
        },
        {
            name: 'Application',
            selector: row => row.Application,
            sortable: true,
            grow: 5,
            wrap: true
        },
        {
            name: 'User Email',
            selector: row => row.User_email,
            sortable: true,
            grow: 5,
            wrap: true
        },
        {
            name: 'Name',
            selector: row => row.Name,
            sortable: true,
            grow: 3,
            wrap: true

        },
        {
            name: 'Logo',
            selector: row => row.Logo,
            grow: 0,
            sortable: true,
        },
        {
            name: 'Created',
            selector: row => row.Created,
            sortable: true,
            grow: 5,
            wrap: true
        },
        {
            name: 'Action',
            selector: row => row.Action,
            button: true,
            grow: 1,
            wrap: true
        },

    ];
    const showPopUp = (id) => {
        setShowDelete(true)
        setID(id)
    }
    const onConfirm = () => {
        deleteCateory(id)
    }
    const deleteCateory = (id) => {
        setLoader(true)
        setCategory([])

        setShowDelete(false)
        axios({
            method: 'POST',
            url: `${baseURL}api/delete-category`,
            data: { id: id, app_id: app.currentApp.id },
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + users.token }
        }).then(res => {
            if (res.data.status === true) {
                setCategory(res.data.data);
                dispatch({ type: 'SUCCESS', payload: res.data.message });
                setCategory([])
                fetchCategory()
            }

            setLoader(false)
        }).catch(error => {
            setLoader(false)
            setShowDelete(false)
            console.log(error);
        });

    }

    const data = []
    if (category) {
        category.forEach((ele, index) => {

            let myData = {
                Id: ele.id,
                Application: ele.app_id,
                User_email: ele.user_id,
                Name: ele.name,
                Logo: <img className="category_logo" src={ele.logo} style={{ width: "30px" }}></img>,
                Created: ele.created,
                Action: (
                    <>
                        <Link to={`/edit-category/${ele.id}`}>
                            <i style={{ marginRight: "10px" }}
                                className="fa fa-pencil-alt"
                                data-toggle="tooltip"
                                data-original-title="edit user"
                                title="edit category" />
                        </Link>
                        <i onClick={() => { showPopUp(ele.id) }}
                            onMouseOver={(e) => { e.target.style.color = "#e83e8c" }}
                            onMouseLeave={(e) => { e.target.style.color = "#6777ef" }}
                            className="fa fa-trash-alt"
                            style={{ cursor: "pointer", color: "#6777ef" }}
                            data-toggle="tooltip"
                            data-original-title="delete user"
                            title="delete category"
                        />
                    </>)
            }
            data.push(myData);
        })
    }

    return (
        <div>
            <Helmet>
                <title> Super-Admin | Videos </title>
            </Helmet>
            <section>

                <div id="app">
                    <div className="main-wrapper main-wrapper-1">
                        <div className="navbar-bg" />


                        {/* Main Content */}
                        <div className="main-content">
                            <section className="section">
                                <div className="section-header">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                            <div className="section-header-breadcrumb-content">
                                                <h1>DataTables</h1>
                                                <div className="section-header-breadcrumb">
                                                    <div className="breadcrumb-item active"><a href="#"><i className="fas fa-home" /></a></div>
                                                    <div className="breadcrumb-item"><a href="#">SuperAdmin</a></div>
                                                    <div className="breadcrumb-item"><a href="#">Users</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header d-flex justify-content-between">
                                                    <h4>Videos</h4>
                                                    <div className="search__user">

                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        < ReactTable
                                                            columns={columns}
                                                            data={data}
                                                            selectableRows
                                                            pagination
                                                            progressPending={category ? false : true}
                                                            responsive={true}
                                                            highlightOnHover={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </section>

                        </div>

                    </div>
                </div>
                {
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="light"
                        title="Are you sure?"
                        focusCancelBtn
                        customClass="customAlertClass"
                        onConfirm={onConfirm}
                        onCancel={() => { setShowDelete(false) }}
                        show={showDelete}
                    >
                        Do you want to delete category ID {id}
                    </SweetAlert>
                }
            </section>
        </div>
    )

};

export default ViewCategory;
