import React, {useState} from "react";
import Helmet from "react-helmet";
import {useDispatch, useSelector} from "react-redux";
import {updateApp} from '../../store/actions/appActions';

const EditApplication = (props) =>{

    const dispatch = useDispatch();
    const params = props.match.params;
    const apps = useSelector(state => state.app.apps);
    let app_data = apps.filter(app => app.id === params.id);

    const [loader, setLoader] = useState({
       loader: false
    });

    const [viewApp, setViewApp] = useState(app_data[0]);
    const [app, setApp] = useState({
        id: viewApp.id,
        name: viewApp.name,
        domain: viewApp.domain,
        secret_key: viewApp.secret_key,
        logo: '',
        description: viewApp.description,
        status: viewApp.status
    });

    const onInputChange = (e) =>{
        setApp({...app,[e.target.name]: e.target.value});
    };
    const onChangeImage = (e) => {
        setApp({...app,logo: e.target.files[0]});
        
    };
    const enableDisable = (e) => {
        if(app.status === "1") {
            setApp({...app, status: "0"});
        } else {
            setApp({...app, status: "1"});
        }
    }
    const onFormSubmit = (e) =>{
        setLoader({loader: true});
        e.preventDefault();
        const form = new FormData();
        form.append('id',app.id);
        form.append('name',app.name);
        form.append('domain',app.domain);
        form.append('secret_key',app.secret_key);
        form.append('logo',app.logo);
        form.append('description',app.description);
        form.append('status',app.status);

        dispatch(updateApp(form,setLoader));
    };

    return(
        <>
            <div>
                <Helmet>
                    <title> Super-Admin | Users </title>
                </Helmet>
                <section>

                    <div id="app">
                        <div className="main-wrapper main-wrapper-1">
                            <div className="navbar-bg" />


                            {/* Main Content */}
                            <div className="main-content">
                                <section className="section">
                                    <div className="section-header">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                <div className="section-header-breadcrumb-content">
                                                    <h1>DataTables</h1>
                                                    <div className="section-header-breadcrumb">
                                                        <div className="breadcrumb-item active"><a href="#"><i className="fas fa-home" /></a></div>
                                                        <div className="breadcrumb-item"><a href="#">ReelApps</a></div>
                                                        <div className="breadcrumb-item"><a href="#">Users</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card">
                                                    <div className="card-header d-flex justify-content-between">
                                                        <h4>Applications</h4>
                                                        <div className="search__user">

                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <form method="post" onSubmit={(e) => onFormSubmit(e)}>
                                                            <div className="pretty p-switch">
                                                                <input type="checkbox" defaultValue={viewApp.status} checked={app.status.includes(1)} onChange={(e) => enableDisable(e)}/>
                                                                <div className="state p-primary">
                                                                    <label>{app.status.includes(1) ? 'Active' : 'Disable'}</label>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-group col-md-6 col-12 text-left">
                                                                    <label>Name</label>
                                                                    <div className="input-group mb-3">
                                                                        <input type="text" className="form-control" name="name" onChange={(e) => onInputChange(e)}  defaultValue={app.name} />
                                                                    </div>
                                                                </div>

                                                                <div className="form-group col-md-6 mb-1 text-left">
                                                                    <label>Domain</label>
                                                                    <div className="input-group mb-3">
                                                                        <input type="text" className="form-control" name="domain" onChange={(e) => onInputChange(e)}  defaultValue={app.domain} />
                                                                    </div>
                                                                </div>

                                                                <div className="form-group col-md-6 mb-1 text-left">
                                                                    <label>Secret Key</label>
                                                                    <div className="input-group mb-3">
                                                                        <input type="text" className="form-control" name="secret_key" onChange={(e) => onInputChange(e)}  defaultValue={app.secret_key} />
                                                                    </div>
                                                                </div>

                                                                <div className="form-group col-md-6 mb-1 text-left">
                                                                    <label>Upload Logo</label>
                                                                    <div className="input-group mb-3">
                                                                        <input type="file" name="logo" onChange={(e) => onChangeImage(e)} accept="png,jpeg,jpg" data-toggle="tooltip" title="PNG, JPEG, JPG File Within 5MB is allowed" />
                                                                        <div>
                                                                            <img src={viewApp.logo} style={{width: 30}} />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="form-group col-md-12 mb-1 text-left">
                                                                    <label>Description</label>
                                                                    <div className="input-group mb-3">
                                                                        <textarea className="form-control" value={app.description} onChange={(e) => onInputChange(e)} name="description" >{app.description}</textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-footer">
                                                                <button className="btn btn-primary" type="submit">
                                                                    { loader.loader ? <i className="fa fa-spinner fa-spin mr-1"/> : ''  } Update Application
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </section>

                            </div>

                        </div>
                    </div>

                </section>
            </div>
        </>
    )
};

export default EditApplication;
