import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactTable from 'react-data-table-component';
import { baseURL } from "../../Global/Global";

const ViewFont = () => {

    const [fonts, setFonts] = useState();

    useEffect(() => {

        axios({
            method: 'POST',
            url: `${baseURL}fetch-fonts`,
            data: {},
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            if (res.data.status === true) {
                setFonts(res.data.data);
            }
        }).catch(err => {

        })

    }, []);



    const columns = [
        {
            name: 'S No.',
            selector: row => row.Id,
            sortable: true,
            // grow: 0,
            wrap: true,
        },
        {
            name: 'Name',
            selector: row => row.Name,
            sortable: true,
            // grow: 0,
            wrap: true
        },
        {
            name: 'Created',
            selector: row => row.Created,
            sortable: true,
            // grow: 0,
            wrap: true
        },
        {
            name: 'Action',
            selector: row => row.Action,
            button: true,
            // grow: 0,
            wrap: true
        },

    ];

    const data = []
    if (fonts) {
        fonts.forEach((ele, index) => {
            let myData = {
                Id: index + 1,
                Name: ele.name,
                Created: ele.created,
                Action: <Link to={`/reelapp/user/${ele.user_id}`}> <i className="fa fa-pencil-alt" data-toggle="tooltip" data-original-title="edit user" /></Link>
            }
            data.push(myData);
        })
    }



    return (
        <>
            <div>
                <Helmet>
                    <title> Super-Admin | View Fonts </title>
                </Helmet>
                <section>

                    <div id="app">
                        <div className="main-wrapper main-wrapper-1">
                            <div className="navbar-bg" />


                            {/* Main Content */}
                            <div className="main-content">
                                <section className="section">
                                    <div className="section-header">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                <div className="section-header-breadcrumb-content">
                                                    <h1>Fonts</h1>
                                                    <div className="section-header-breadcrumb">
                                                        <div className="breadcrumb-item active"><a href="#"><i className="fas fa-home" /></a></div>
                                                        <div className="breadcrumb-item"><a href="#">ReelApps</a></div>
                                                        <div className="breadcrumb-item"><a href="#">Font</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card">
                                                    <div className="card-header d-flex justify-content-between">
                                                        <h4>Applications</h4>
                                                        <div className="search__user">

                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="table-responsive">
                                                            < ReactTable
                                                                columns={columns}
                                                                data={data}
                                                                selectableRows
                                                                pagination
                                                                progressPending={fonts ? false : true}
                                                                responsive={true}
                                                                highlightOnHover={true}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </section>

                            </div>

                        </div>
                    </div>

                </section>
            </div>
        </>
    )
}


export default ViewFont;