import React, {useEffect, useState} from "react";
import axios from "axios";
import {useDispatch} from "react-redux";
import Skeleton from 'react-loading-skeleton';

const Applications = ({app,user}) => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(true);
    const [existence, setExistence] = useState(false);
    const checkUser = () => {
        if(loader) {
            axios({
                method: 'POST',
                url: app.endpoints.check_user,
                data: {secret_key: app.secret_key, email: user.email},
                headers: {'Content-type': 'application/json'}
            }).then(res => {
                setLoader(false);
                if(res.data.status === true) {
                    setExistence(true);
                }
            }).catch(error => {
                setLoader(false);
            });
        }
    }

    const createUser = (e) => {
        let random_string = require("randomstring");

        const new_user = {
            secret_key: app.secret_key,
            email: user.email,
            password: random_string.generate({length: 12, charset: 'alphabetic'}),
            user_type: 'user',
            name: user.name,
            account_tag: 'Centralized',
            membership: [app.front_end],
            send_email : true,
            send_gr_email: true
        }

        axios({
            method: 'POST',
            url: app.endpoints.add_user,
            data: new_user,
            headers: {'Content-Type': 'application/json'}
        }).then(res => {
            if(res.data.status === true) {
                dispatch({type:'SUCCESS',payload: res.data.message});
                setLoader(true);
                checkUser();
            } else {
                dispatch({type:'ERROR',payload: res.data.message});
            }
        }).catch(error =>{
        })
    }
    useEffect(()=> {
        checkUser();
    });
    return (
        <div>
        { loader ? <Skeleton height={25}/> :
            <p className="clearfix">
            <span className={existence ? "float-left" : "float-left text-muted"}>
                <img className={existence ? 'img-responsive pr-2' : 'img-responsive pr-2 grayscale'} width="35" src={app.logo}/>
                {app.name}
            </span>
                {

                }
                {
                    !existence ?
                        <>
                            <span className="float-right">
                                <input type="checkbox" id="status" key={app.id} name="status"
                                       data-toggle="collapse"
                                       onChange={e => createUser(e)}
                                value={app.id}/>
                            </span>
                        </>
                        :
                        ''
                }
            </p>
        }
        </div>
    )
}

export default Applications;
