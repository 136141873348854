import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import next from "react-id-generator";
import { baseURL } from "../../Global/Global";

const Endpoint = ({
  curElem,
  onSelectChange,
  onInputEndpoint,
  removeSlide,
  index,
}) => {
  const auth = useSelector((state) => state.auth);
  const [features, setFeatures] = useState([]);
  const uid = next("");

  useEffect(() => {
    axios({
      method: "POST",
      url: `${baseURL}fetch-feature`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
    })
      .then((res) => {
        if (res.data.status === true) {
          setFeatures(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onSelectInputChange = (e, index) => {
    onSelectChange(e.target.value, index);
  };

  const onEndpointSelect = (e, index) => {
    onInputEndpoint(e.target.value, index);
  };

  const slideRemove = (index) => {
    // console.log(index,"index")
    removeSlide(index);
  };

  return (
    <div className="row">
      <div className="col-xl-5 col-lg-5 col-md-12 mb-1">
        <fieldset className="form-group text-left">
          <label htmlFor="helperText">Features:</label>
          <select
            className="form-control"
            value={curElem.feature}
            onChange={(e) => onSelectInputChange(e, index)}
          >
            <option>Select Features</option>
            {features !== ""
              ? features.map((feature) => (
                  <option key={feature.id} value={feature.id}>
                    {feature.name}
                  </option>
                ))
              : ""}
          </select>
        </fieldset>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-12 mb-1">
        <fieldset className="form-group text-left">
          <label htmlFor="helperText">Endpoints:</label>
          <input
            type="text"
            name="endpoint"
            id={uid}
            value={curElem.endpoint}
            className="form-control"
            onChange={(e) => onEndpointSelect(e, index)}
            placeholder="Endpoint"
            required
          />
        </fieldset>
      </div>

      <div className="col-xl-1 col-lg-1 col-md-12 mb-1">
        <fieldset className="form-group text-left">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => slideRemove(index)}
          >
            <i className="fa fa-minus" />
          </button>
        </fieldset>
      </div>
    </div>
  );
};

export default Endpoint;
