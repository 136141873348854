import React, {useEffect, useState} from "react";
import Helmet from "react-helmet";
import CKEditor from "ckeditor4-react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {addArticle, editArticle} from "../../store/actions/appActions";
import { baseURL } from "../../Global/Global";

const EditArticle = (props) => {

    const dispatch = useDispatch();
    const users = useSelector(state => state.auth);
    const app = useSelector(state => state.app);

    const [category,setCategory] = useState([]);
    const [dataAdd, setDataAdd] = useState(false);
    const [artData, setArtData] = useState([]);
    const [article,setArticles] = useState([]);
    const [loader, setLoader] = useState(false);

    const [articleData,setArticleData] = useState({
        id: '',
        app_id: app.currentApp.id,
        user_id: users.user.user_id,
        name: '',
        category: '',
        items: []
    });

    const [newArr, setNewArr] = useState({
       content: '',
       feature_url: '',
       title: ''
    });

    const addSummerNote = (e) => {
        let arr = [...articleData.items];
        arr.push(newArr);

        console.log(arr);

        setArticleData({...articleData, items: arr});
    }

    const onChangeImage = (e,index) => {
        let tempThumb = [...articleData.items];
        let thumb = {...tempThumb[index]};
        thumb.feature_url = e.target.files[0];

        tempThumb[index] = thumb;
        setArticleData({...articleData, items: tempThumb});
    }

    const onInputTitle = (e,index) => {
        let tempArr = [...articleData.items];
        let item = {...tempArr[index]};

        item.title = e.target.value;
        tempArr[index] = item;

        setArticleData({...articleData, items: tempArr});
    }

    const onInputSummerNote = (value,index) => {
        let tempNote = [...articleData.items];
        let content = {...tempNote[index]};
        content.content = value;

        tempNote[index] = content;
        setArticleData({...articleData, items: tempNote});
    }

    const onFormSubmit = (e) => {
        e.preventDefault();

       
        const form = new FormData();
        form.append('id',articleData.id);
        form.append('app_id',articleData.app_id);
        form.append('user_id',articleData.user_id);
        form.append('name',articleData.name);
        form.append('category',articleData.category);

        for (let i = 0; i < articleData.items.length; i++){
            // console.log(articleData.items[i].title);
            form.append(`title[${i}]`,articleData.items[i].title);
            form.append(`feature[${i}]`,articleData.items[i].feature_url);
            form.append(`content[${i}]`,articleData.items[i].content);
        }
        dispatch(editArticle(form,setLoader));
    }

    const onInputChange = (e) => {
        setArticleData({...articleData, [e.target.name] : e.target.value});
    }

    const fetchArticles = () => {
        axios({
            method: 'POST',
            url: `${baseURL}api/fetch-article`,
            data: {app_id: app.currentApp.id},
            headers: {'Content-Type': 'application/json','Authorization': 'Bearer '+users.token}
        }).then(res=>{
            
            if(res.data.status === true){
                let params = props.match.params;
                let filterData = res.data.data.find(cat => cat.id === params.id);
                

                setArticleData({...articleData,id: params.id, category: filterData.category_id, name: filterData.tags, items: filterData.items})

                setArtData(filterData);
                setLoader(false)
                setDataAdd(true);
            }
        }).catch(error=>{
            console.log(error);
        });
    }

    useEffect(()=>{
        fetchArticles();
    },[])

    useEffect(()=>{
        axios({
            method: 'POST',
            url: `${baseURL}api/fetch-categories`,
            data: {app_id: app.currentApp.id},
            headers: {'Content-Type': 'application/json','Authorization': 'Bearer '+users.token}
        }).then(res=>{
            if(res.data.status === true){
                setCategory(res.data.data);
            }
        }).catch(error=>{
            console.log(error);
        });
    },[category.length]);


    useEffect(()=>{
        setArticles(artData);
    },[artData])

    return(
        <>
            <Helmet>
                <title> Super-Admin | Edit Category</title>
            </Helmet>
            <section >
                <div>
                    <div id="app">
                        <div className="main-wrapper main-wrapper-1">
                            <div className="navbar-bg" />
                            {/* Main Content */}
                            <div className="main-content">
                                <section className="section">
                                    <div className="section-header">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                <div className="section-header-breadcrumb-content">
                                                    <h1>Add Article</h1>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="card-title-wrap bar-success">
                                                        <h4 className="card-title mb-0">Article Details</h4>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="px-3">
                                                        <form action="" onSubmit={(e) => onFormSubmit(e)} method="post" acceptCharset="utf-8">
                                                            <div className="form-body">
                                                                <div className="row">

                                                                    <div className="col-xl-6 col-lg-6 col-md-12 mb-1">
                                                                        <fieldset className="form-group text-left">
                                                                            <label htmlFor="helperText">Tags:</label>
                                                                            <input type="text" id="helperText" onChange={(e) => onInputChange(e)}
                                                                                   name="tags" className="form-control"  placeholder="Enter Tags"
                                                                                   defaultValue={article?.tags}
                                                                                   required  />
                                                                        </fieldset>
                                                                    </div>

                                                                    <div className="col-xl-6 col-lg-6 col-md-12 mb-1">
                                                                        <fieldset className="form-group text-left">
                                                                            <label htmlFor="helperText">Category:</label>
                                                                            <select id="select" name="category" className="form-control" onChange={(e) => onInputChange(e)}>
                                                                                <option value="" selected disabled>-- Select Category --</option>
                                                                                {
                                                                                    category.length > 0 ?
                                                                                        category.map((item,index)=>{
                                                                                            return(
                                                                                                <option value={item.id} key={index}>{item.name}</option>
                                                                                            )
                                                                                        })
                                                                                        : ''
                                                                                }
                                                                            </select>
                                                                        </fieldset>
                                                                    </div>


                                                                    <div className="col-xl-12 col-lg-12 col-md-12 mb-1" id="addEndpoint">
                                                                        {
                                                                            dataAdd ?
                                                                                articleData.items.map((value,index) => (
                                                                                    <>
                                                                                        <div className="col-xl-5 col-lg-5 col-md-12 mb-1" key={index}>
                                                                                            <fieldset className="form-group text-left">
                                                                                                <label htmlFor="helperText">Title:</label>
                                                                                                <input type="text" onChange={(e) => onInputTitle(e, index)}
                                                                                                       name="text" className="form-control"  placeholder="Enter Title"
                                                                                                       defaultValue={value.title}
                                                                                                       required   />
                                                                                            </fieldset>
                                                                                        </div>

                                                                                        <div className="col-xl-6 col-lg-6 col-md-12 mb-1">
                                                                                            <fieldset className="form-group text-left">
                                                                                                <label htmlFor="helperText">Feature URL:</label>
                                                                                                <input type="file" id="helperText" onChange={(e) => onChangeImage(e,index)} name="file" className="form-control"   />
                                                                                            </fieldset>
                                                                                        </div>

                                                                                        <div className="col-xl-12 col-lg-12 col-md-12 mb-1">
                                                                                            <CKEditor
                                                                                                name="editor"
                                                                                                data={value.content}
                                                                                                onChange={evt => onInputSummerNote( evt.editor.getData() , index)}
                                                                                            />

                                                                                        </div>
                                                                                    </>
                                                                                ))
                                                                            : ''
                                                                        }
                                                                    </div>

                                                                    <div className="col-xl-12 col-lg-12 col-md-12 mb-1 text-center">
                                                                        <button type="button" onClick={(e) => addSummerNote(e)} className="btn btn-primary fas fa-plus-circle" title="Add More"></button>
                                                                        {/*<button type="button" onClick={(e) => removeMoreEndpoint(e)} className="btn btn-info fas fa-minus-circle" title="Remove Endpoints"></button>*/}
                                                                    </div>

                                                                    <div className="col-xl-12 col-lg-6 col-md-12 mb-1">
                                                                        <fieldset className="form-group text-left">
                                                                            <button type="submit" className="btn btn-primary pull-right">
                                                                                {loader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Update Article
                                                                            </button>
                                                                        </fieldset>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EditArticle;