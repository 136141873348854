import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactTable from 'react-data-table-component';
const Datatable = () => {

  const currentApp = useSelector(state => state.app.currentApp);
  const allUsersData = useSelector(state => state.reelapps.users);
  const currentDiv = useSelector(state => state.reelapps.current_div);
  let updatedAllUserData

  useEffect(() => {
    if (currentApp.name === "AIsocial") {

      updatedAllUserData = allUsersData.map(data => ({
        ...data,
        type: data.type.replace("premium", "elite")
      }));
    }
    else {
      updatedAllUserData = allUsersData
    }
  }, [currentApp, allUsersData])

  const [users, setUsers] = useState([]);
  const [isRender, setIsRender] = useState(false);

  const setUserData = (allUsers) => {
    if (allUsers) {
      if (currentDiv === "total_users") {
        let data = []
        allUsers?.forEach((user) => {
          data.push(user)
        })
        setUsers(data)
      }
      else if (currentDiv === "active_users") {
        let data = []
        allUsers?.forEach((user) => {
          if (user.status === "1") {
            data.push(user)
          }
        })
        setUsers(data)
      }
      else if (currentDiv === "disabled_users") {
        let data = []
        allUsers?.forEach((user) => {
          if (user.status === "0") {
            data.push(user)
          }
        })
        setUsers(data)
      }
      else if (currentDiv === "client_users") {
        let data = []
        allUsers?.forEach((user) => {
          if (user.is_client_account === "1") {
            data.push(user)
          }
        })
        setUsers(data)
      }
      else if (currentDiv === "team_users") {
        let data = []
        allUsers?.forEach((user) => {
          if (user.user_type === 3) {
            data.push(user)
          }
        })
        setUsers(data)
      } else {
        setUsers(allUsers)
      }
    }
  }

  const [q, setQ] = useState('');

  const search = (users) => {
    return users.filter(
      user => (user.name || user.user_fname) || (user.email || user.user_email) ? user?.name?.toLowerCase().indexOf(q.toLowerCase()) > -1 || user?.user_fname?.toLowerCase().indexOf(q.toLowerCase()) > -1 || user?.email?.toLowerCase().indexOf(q.toLowerCase()) > -1 || user?.user_email?.toLowerCase().indexOf(q.toLowerCase()) > -1 : ''
    );
  };

  const getQ = (e) => {
    setQ(e.target.value)
  };



  const columns = [
    {
      name: 'Id',
      selector: row => row.Id,
      sortable: true,
      grow: 1,
      wrap: true
    },
    {
      name: 'Name',
      selector: row => row.Name,
      sortable: true,
      grow: 5,
      wrap: true
    },
    {
      name: 'Email',
      selector: row => row.Email,
      sortable: true,
      grow: 5,
      wrap: true
    },
    {
      name: 'register by',
      selector: row => row.register_by,
      sortable: true,
      grow: 3,
      wrap: true

    },
    {
      name: 'type',
      selector: row => row.type,
      grow: 6,
      wrap: true
    },
    {
      name: 'Created',
      selector: row => row.Created,
      sortable: true,
      grow: 5,
      wrap: true
    },
    {
      name: 'Action',
      selector: row => row.Action,
      button: true,
      grow: 1,
      wrap: true
    },

  ];

  const data = []
  if (users) {
    search(users).forEach((ele, index) => {

      let myData = {
        Id: ele.user_id ? ele.user_id : ele.id,
        Name: <span class="d-flex align-items-center"> <img src={ele.profile_image ? ele.profile_image : "../../assets/images/default_profile.png"} class="mr-2 round" style={{ width: "40px" }} />{ele.name ? ele.name : ele.first_name ? ele.first_name : ele.username ? ele.username : ele.user_fname}</span>,
        Email: ele.email ? ele.email : ele.user_email,
        register_by: ele.register_by ? ele.register_by : ele.created_mode ? ele.created_mode : ele.source ? ele.source : ele.account_tag ? ele.account_tag : ele.registered_by,
        type: <textarea value={ele.type ? JSON.stringify(ele.type) : JSON.stringify(ele.track)} className="form-control" readOnly={true} >{ele.type ? JSON.stringify(ele.type) : JSON.stringify(ele.track)}</textarea>,
        Created: ele.created,
        Action: <Link to={`/reelapp/user/${ele.user_id ? ele.user_id : ele.id}`}> <i className="fa fa-pencil-alt" data-toggle="tooltip" data-original-title="edit user" /></Link>
      }
      data.push(myData);
    })
  }


  useEffect(() => {
    setUserData(updatedAllUserData)
    setIsRender((prev) => !prev)
  }, [updatedAllUserData, allUsersData]);

  return (
    <div >
      <Helmet>
        <title> Super-Admin | Users </title>
      </Helmet>
      <section>

        <div id="app">
          <div className="main-wrapper main-wrapper-1">
            <div className="navbar-bg" />


            {/* Main Content */}
            <div className="main-content">
              <section className="section">
                <div className="section-header">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                      <div className="section-header-breadcrumb-content">
                        <h1>View Users</h1>
                        <div className="section-header-breadcrumb">
                          <div className="breadcrumb-item active"><a href="#"><i className="fas fa-home" /></a></div>
                          <div className="breadcrumb-item"><a href="#">ReelApps</a></div>
                          <div className="breadcrumb-item"><a href="#">Users</a></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-header d-flex justify-content-between">
                          <h4>{currentApp.name} Users</h4>
                          <div className="search__user">
                            <input type="text" className="form-control" value={q} onChange={(e) => getQ(e)} placeholder="Search here..." />
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <ReactTable
                              columns={columns}
                              data={data}
                              selectableRows
                              pagination
                              progressPending={users.length === 0 ? true : false}
                              responsive={true}
                              highlightOnHover={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="settingSidebar">
                <a onClick={e => e.preventDefault()} className="settingPanelToggle"> <i className="    fas fa-adjust" />
                </a>
                <div className="settingSidebar-body ps-container ps-theme-default">
                  <div className=" fade show active">
                    <div className="setting-panel-header">Theme Customizer</div>
                    <div className="p-15 border-bottom">
                      <h6 className="font-medium m-b-10">Theme Layout</h6>
                      <div className="selectgroup layout-color w-50">
                        <label> <span className="control-label p-r-20">Light</span>
                          <input type="radio" name="custom-switch-input" defaultValue={1} className="custom-switch-input" defaultChecked /> <span className="custom-switch-indicator" />
                        </label>
                      </div>
                      <div className="selectgroup layout-color  w-50">
                        <label> <span className="control-label p-r-20">Dark&nbsp;</span>
                          <input type="radio" name="custom-switch-input" defaultValue={2} className="custom-switch-input" /> <span className="custom-switch-indicator" />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="p-15 border-bottom">
                    <h6 className="font-medium m-b-10">Sidebar Colors</h6>
                    <div className="sidebar-setting-options">
                      <ul className="sidebar-color list-unstyled mb-0">
                        <li title="white" className="active">
                          <div className="white" />
                        </li>
                        <li title="blue">
                          <div className="blue" />
                        </li>
                        <li title="coral">
                          <div className="coral" />
                        </li>
                        <li title="purple">
                          <div className="purple" />
                        </li>
                        <li title="allports">
                          <div className="allports" />
                        </li>
                        <li title="barossa">
                          <div className="barossa" />
                        </li>
                        <li title="fancy">
                          <div className="fancy" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="p-15 border-bottom">
                    <h6 className="font-medium m-b-10">Theme Colors</h6>
                    <div className="theme-setting-options">
                      <ul className="choose-theme list-unstyled mb-0">
                        <li title="white" className="active">
                          <div className="white" />
                        </li>
                        <li title="blue">
                          <div className="blue" />
                        </li>
                        <li title="coral">
                          <div className="coral" />
                        </li>
                        <li title="purple">
                          <div className="purple" />
                        </li>
                        <li title="allports">
                          <div className="allports" />
                        </li>
                        <li title="barossa">
                          <div className="barossa" />
                        </li>
                        <li title="fancy">
                          <div className="fancy" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="p-15 border-bottom">
                    <h6 className="font-medium m-b-10">Layout Options</h6>
                    <div className="theme-setting-options">
                      <label> <span className="control-label p-r-20">Compact
                        Sidebar Menu</span> <input type="checkbox" name="custom-switch-checkbox" className="custom-switch-input" id="mini_sidebar_setting" /> <span className="custom-switch-indicator" />
                      </label>
                    </div>
                  </div>
                  <div className="mt-3 mb-3 align-center">
                    <a href="#" className="btn btn-icon icon-left btn-outline-primary btn-restore-theme">
                      <i className="fas fa-undo" /> Restore Default
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </section>
    </div>
  );
}



export default Datatable;




