import axios from "axios";
import { baseURL } from "../../Global/Global";

export const loginUser = (user) => (dispatch, getState) => {
    dispatch({type:'USER_LOADING'});

    axios({
        method: 'POST',
        url : `${baseURL}validate-login`,
        data: user,
        headers: {'Content-Type': 'application/json' }
    }).then(res =>{
        if(res.data.status === true) {
            dispatch({type:'LOGIN_USER',payload: res.data.data});
        } else {
            dispatch({type:'USER_ERROR'});
            dispatch({type:'ERROR', payload:res.data.message})
        }
    }).catch(error => {
        console.log(error);
    })
}

export const logoutUser = () => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: `${baseURL}logout`,
        data: {},
        headers: {'Content-Type': 'application/json','Authorization': 'Bearer '+getState().auth.token}
    }).then(res => {
       if(res.data.status === true) {
           dispatch({type:'LOGOUT'});
       } else {
       }
    }).catch(error => {
       console.log(error);
    });
}

export const forgetPassword = (data) => (dispatch,getState) => {
    axios({
        method: 'POST',
        url: `${baseURL}forget-password`,
        data: {email:data},
        headers: {'Content-Type': 'application/json'}
    }).then(res => {
       if(res.data.status === true){
           dispatch({type:'SUCCESS', payload:res.data.message})
       }else{
           dispatch({type:'ERROR', payload:res.data.message})
       }
    }).catch(error => {
        console.log(error);
    });
};

export const resetPassword = (data) => (dispatch,getState) => {
    axios({
        method: 'POST',
        url: `${baseURL}reset-password`,
        data: data,
        headers: {'Content-Type': 'application/json'}
    }).then(res => {
        if(res.data.status === true){
            dispatch({type:'SUCCESS', payload:res.data.message})
        }else{
            dispatch({type:'ERROR', payload:res.data.message})
        }
    }).catch(error => {
        console.log(error);
    });
};

export const updateEmail = (data,setLoaderEmail) => (dispatch,getState) => {
  axios({
      method: 'POST',
      url: `${baseURL}change-email`,
      data: data,
      headers: {'Content-Type': 'application/json','Authorization': 'Bearer '+getState().auth.token}
  }).then(res =>{
      if(res.data.status === true){
          dispatch({type:'SUCCESS', payload:res.data.message});
          dispatch({type: 'UPDATE_ROOT_EMAIL',payload: res.data.data});
      }else{
          dispatch({type:'ERROR', payload:res.data.message})
      }
      setLoaderEmail({loader: false});
  }).catch(error =>{
      console.log(error);
      setLoaderEmail({loader: false});
  });
};


export const updateName = (data,setLoaderName) => (dispatch, getState) =>{
    axios({
        method: 'POST',
        url: `${baseURL}change-name`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': 'Bearer '+getState().auth.token}
    }).then(res =>{
        if(res.data.status === true){
            dispatch({type: 'SUCCESS',payload: res.data.message});
            dispatch({type: 'UPDATE_ROOT_NAME', payload: res.data.data});
        }else{
            dispatch({type:'ERROR', payload:res.data.message});
        }
        setLoaderName({loader: false});
    }).catch(error =>{
        console.log(error);
        setLoaderName({loader: false});
    })
};


export const updatePassword = (data,setLoaderPassword) => (dispatch,getState) =>{
    axios({
        method: 'POST',
        url: `${baseURL}change-password`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': 'Bearer '+getState().auth.token}
    }).then(res =>{
        if(res.data.status === true){
            dispatch({type: 'SUCCESS',payload: res.data.message});
            dispatch({type: 'UPDATE_ROOT_PASSWORD', payload: res.data.data});
            setLoaderPassword({loader: false});
        }else{
            dispatch({type:'ERROR', payload:res.data.message});
            setLoaderPassword({loader: false});
        }
    }).catch(error =>{
        console.log(error);
        setLoaderPassword({loader: false});
    })
};


export const updateStatus = (data) => (dispatch,getState) =>{
    axios({
        method: 'POST',
        url: `${baseURL}admin-status`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': 'Bearer '+getState().auth.token}
    }).then(res =>{
        if(res.data.status === true){
            dispatch({type: 'SUCCESS',payload: res.data.message});
            dispatch({type: 'UPDATE_ROOT_STATUS', payload: res.data.data});
        }else{
            dispatch({type:'ERROR', payload:res.data.message});
        }
    }).catch(error =>{
        console.log(error);
    })
};


export const updateDeleted = (data) => (dispatch,getState) =>{
    axios({
        method: 'POST',
        url: `${baseURL}admin-delete-status`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': 'Bearer '+getState().auth.token}
    }).then(res =>{
        if(res.data.status === true){
            dispatch({type: 'SUCCESS',payload: res.data.message});
            dispatch({type: 'UPDATE_ROOT_DELETED', payload: res.data.data});
        }else{
            dispatch({type:'ERROR', payload:res.data.message});
        }
    }).catch(error =>{
        console.log(error);
    })
};

export const updateRoot = (data) => (dispatch,getState) =>{
    axios({
        method: 'POST',
        url: `${baseURL}admin-root`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': 'Bearer '+getState().auth.token}
    }).then(res =>{
        if(res.data.status === true){
            dispatch({type: 'SUCCESS',payload: res.data.message});
            dispatch({type: 'UPDATE_IS_ROOT',payload: res.data.data});
        }else{
            dispatch({type:'ERROR', payload:res.data.message});
        }
    }).catch(error =>{
        console.log(error);
    })
};


export const updateProfile = (data) => (dispatch,getState) =>{
    axios({
        method: 'POST',
        url: `${baseURL}change-profile`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': 'Bearer '+getState().auth.token}
    }).then(res =>{
        if(res.data.status === true){
            dispatch({type: 'SUCCESS',payload: res.data.message});
            dispatch({type: 'UPDATE_ROOT_PROFILE',payload: res.data.data})
        }else{
            dispatch({type:'ERROR', payload:res.data.message});
        }
    }).catch(error =>{
        console.log(error);
    })
};


export const addAdminUser = (data,setLoader) => (dispatch,getState) =>{
    axios({
        method: 'POST',
        url: `${baseURL}add-user`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': 'Bearer '+getState().auth.token}
    }).then(res =>{
        if(res.data.status === true){
            dispatch({type: 'SUCCESS',payload: res.data.message});
        }else{
            dispatch({type:'ERROR', payload:res.data.message});
        }
        setLoader({isLoading: false});
    }).catch(error =>{
        console.log(error);
        setLoader({isLoading: false});
    })
};


export const updateUserEmail = (data,setLoaderEmail) => (dispatch,getState) => {
    axios({
        method: 'POST',
        url: `${baseURL}change-email`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': 'Bearer '+getState().auth.token}
    }).then(res =>{
        if(res.data.status === true){
            dispatch({type:'SUCCESS', payload:res.data.message});
        }else{
            dispatch({type:'ERROR', payload:res.data.message})
        }
        setLoaderEmail({loader: false});
    }).catch(error =>{
        console.log(error);
        setLoaderEmail({loader: false});
    });
};


export const updateUserName = (data,setLoaderName) => (dispatch, getState) =>{
    axios({
        method: 'POST',
        url: `${baseURL}change-name`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': 'Bearer '+getState().auth.token}
    }).then(res =>{
        if(res.data.status === true){
            dispatch({type: 'SUCCESS',payload: res.data.message});
        }else{
            dispatch({type:'ERROR', payload:res.data.message});
        }
        setLoaderName({loader: false});
    }).catch(error =>{
        console.log(error);
        setLoaderName({loader: false});
    })
};

export const updateUserPassword = (data,setLoaderPassword) => (dispatch,getState) =>{
    axios({
        method: 'POST',
        url: `${baseURL}change-user-password`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': 'Bearer '+getState().auth.token}
    }).then(res =>{
        if(res.data.status === true){
            dispatch({type: 'SUCCESS',payload: res.data.message});
            setLoaderPassword({loader: false});
        }else{
            dispatch({type:'ERROR', payload:res.data.message});
            setLoaderPassword({loader: false});
        }
    }).catch(error =>{
        console.log(error);
        setLoaderPassword({loader: false});
    })
};

export const updateUserDeleted = (data) => (dispatch,getState) =>{
    axios({
        method: 'POST',
        url: `${baseURL}admin-delete-status`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': 'Bearer '+getState().auth.token}
    }).then(res =>{
        if(res.data.status === true){
            dispatch({type: 'SUCCESS',payload: res.data.message});
        }else{
            dispatch({type:'ERROR', payload:res.data.message});
        }
    }).catch(error =>{
        console.log(error);
    })
};

export const updateUserStatus = (data) => (dispatch,getState) =>{
    axios({
        method: 'POST',
        url: `${baseURL}admin-status`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': 'Bearer '+getState().auth.token}
    }).then(res =>{
        if(res.data.status === true){
            dispatch({type: 'SUCCESS',payload: res.data.message});
        }else{
            dispatch({type:'ERROR', payload:res.data.message});
        }
    }).catch(error =>{
        console.log(error);
    })
};


export const updateUserRoot = (data) => (dispatch,getState) =>{
    axios({
        method: 'POST',
        url: `${baseURL}admin-root`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': 'Bearer '+getState().auth.token}
    }).then(res =>{
        if(res.data.status === true){
            dispatch({type: 'SUCCESS',payload: res.data.message});
        }else{
            dispatch({type:'ERROR', payload:res.data.message});
        }
    }).catch(error =>{
        console.log(error);
    })
};

export const updateRootProfile = (data) => (dispatch,getState) =>{
    axios({
        method: 'POST',
        url: `${baseURL}change-profile`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': 'Bearer '+getState().auth.token}
    }).then(res =>{
        if(res.data.status === true){
            dispatch({type: 'SUCCESS',payload: res.data.message});
        }else{
            dispatch({type:'ERROR', payload:res.data.message});
        }
    }).catch(error =>{
        console.log(error);
    })
};
