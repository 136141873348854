import React, { Component, useEffect, useState } from 'react';
import { fetchApps } from "../../store/actions/appActions";
import { reelAppUsers } from "../../store/actions/reelAppsActions";
import { getDivName } from "../../store/actions/reelAppsActions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import { baseURL } from '../../Global/Global';

const Stats = () => {
    const dispatch = useDispatch();
    const currentApp = useSelector(state => state.app.currentApp);
    const auth = useSelector(state => state.auth);
    const [appTotalCount, setAppTotalCount] = useState({
        total_users: null,
        active_users: null,
        disable_users: null
    });

    const [appUsers, setAppUsers] = useState({
        total_users: null,
        active_users: null,
        disable_users: null,
        root_users: null,
        admin_users: null,
        total_apps: null,
        active_apps: null,
        disable_apps: null
    });

    useEffect(() => {
        dispatch(reelAppUsers(currentApp));
    }, [currentApp]);

    useEffect(() => {
        axios({
            method: 'POST',
            url: `${baseURL}fetch-total-users`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setAppTotalCount({
                    active_users: res.data.data.active_users,
                    total_users: res.data.data.total_users,
                    disable_users: res.data.data.disabled_users
                });
            }
        }).catch(error => {
        });
    }, [auth.token]);

    useEffect(() => {
        axios({
            method: 'POST',
            url: `${baseURL}fetch-users`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.token }
        }).then(res => {

            if (res.data.status === true) {
                setAppUsers({
                    total_users: res.data.total_users,
                    disable_users: res.data.disabled_users,
                    active_users: res.data.active_users,
                    root_users: res.data.root_users,
                    admin_users: res.data.admin_users,
                    total_apps: res.data.total_apps,
                    active_apps: res.data.active_apps,
                    disable_apps: res.data.disable_apps
                });
            }
        })
    }, []);

    const reelapps = useSelector(state => state.reelapps);
    console.log(reelapps, "reelapps")

    const handleClick = (e) => {
        let clickElementName = e.currentTarget.getAttribute('name')
        dispatch(getDivName(clickElementName));
    }

    return (
        <section >
            <div>
                <div id="app">
                    <div className="main-wrapper main-wrapper-1">
                        <div className="navbar-bg" />

                        {/* Main Content */}
                        <div className="main-content">
                            <section className="section">
                                <div className="section-header">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                            <div className="section-header-breadcrumb-content">
                                                <h1>Dashboard</h1>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                {/* Current Apps Status */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h6 className="text-left stats-h6">{currentApp.name}</h6>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12" name="total_users" onClick={(e) => { handleClick(e) }}>
                                        <Link to={'/view-user'}>
                                            <div className="card card-sales-widget card-bg-blue-gradient">
                                                <div className="card-icon shadow-primary bg-blue">
                                                    <i className="fas fa-user-plus" />
                                                </div>
                                                <div className="card-wrap pull-right">
                                                    <div className="card-header">
                                                        <h3>{reelapps.total_users >= 0 ?
                                                            reelapps.total_users : <i className="fa fa-spinner fa-spin" />}
                                                        </h3>
                                                        <h4><b> Total Users</b> </h4>
                                                    </div>
                                                </div>
                                                <div className="card-chart">
                                                    <div id="chart-1" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12" name="active_users" onClick={handleClick}>
                                        <Link to={'/view-user'}>
                                            <div className="card card-sales-widget card-bg-yellow-gradient">
                                                <div className="card-icon shadow-primary bg-warning">
                                                    <i className="fas fa-user-check" />
                                                </div>
                                                <div className="card-wrap pull-right">
                                                    <div className="card-header">
                                                        <h3>
                                                            {reelapps.active_users >= 0 ?
                                                                reelapps.active_users : <i className="fa fa-spinner fa-spin" />}
                                                        </h3>
                                                        <h4> <b>Active Users </b></h4>
                                                    </div>
                                                </div>
                                                <div className="card-chart">
                                                    <div id="chart-2" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12" name="disabled_users" onClick={handleClick}>
                                        <Link to={'/view-user'}>
                                            <div className="card card-sales-widget card-bg-orange-gradient">
                                                <div className="card-icon shadow-primary bg-hibiscus">
                                                    <i className="fas fa-user-alt-slash" />
                                                </div>
                                                <div className="card-wrap pull-right">
                                                    <div className="card-header">
                                                        <h3>
                                                            {reelapps.disabled_users >= 0 ?
                                                                reelapps.disabled_users :
                                                                <i className="fa fa-spinner fa-spin" />}

                                                        </h3>
                                                        <h4><b> Disabled Users </b></h4>
                                                    </div>
                                                </div>
                                                <div className="card-chart">
                                                    <div id="chart-3" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12" name="client_users" onClick={handleClick}>
                                        <Link to={'/view-user'}>
                                            <div className="card card-sales-widget card-bg-cyan-gradient">
                                                <div className="card-icon shadow-primary bg-cyan">
                                                    <i className="fas fa-user" />
                                                </div>
                                                <div className="card-wrap pull-right">
                                                    <div className="card-header">
                                                        <h3>
                                                            {reelapps.client_users >= 0 ?
                                                                reelapps.client_users :
                                                                <i className="fa fa-spinner fa-spin" />}
                                                        </h3>
                                                        <h4><b> Client Account </b></h4>
                                                    </div>
                                                </div>
                                                <div className="card-chart">
                                                    <div id="chart-3" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12" name="team_users" onClick={handleClick}>
                                        <Link to={'/view-user'}>
                                            <div className="card card-sales-widget card-bg-cyan-gradient">
                                                <div className="card-icon shadow-primary bg-cyan">
                                                    <i className="fas fa-user" />
                                                </div>
                                                <div className="card-wrap pull-right">
                                                    <div className="card-header">
                                                        <h3>
                                                            {reelapps.team_users >= 0 ?
                                                                reelapps.team_users :
                                                                <i className="fa fa-spinner fa-spin" />}
                                                        </h3>
                                                        <h4><b> Team Member </b></h4>
                                                    </div>
                                                </div>
                                                <div className="card-chart">
                                                    <div id="chart-3" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="col-lg-3 col-md-6 col-sm-12" name="total_projects" onClick={handleClick}>
                                        <Link to="">
                                            <div className="card card-sales-widget card-bg-blue-gradient">
                                                <div className="card-icon shadow-primary bg-blue">
                                                    <i className="fas fa-user" />
                                                </div>
                                                <div className="card-wrap pull-right">
                                                    <div className="card-header">
                                                        <h3>
                                                            {+reelapps.total_projects >= 0 ?
                                                                reelapps.total_projects :
                                                                <i className="fa fa-spinner fa-spin" />}
                                                        </h3>
                                                        <h4><b> Total Projects </b></h4>
                                                    </div>
                                                </div>
                                                <div className="card-chart">
                                                    <div id="chart-3" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12" name="success_projects" onClick={handleClick}>
                                        <Link to="">
                                            <div className="card card-sales-widget card-bg-green-gradient">
                                                <div className="card-icon shadow-primary bg-green">
                                                    <i className="fas fa-user" />
                                                </div>
                                                <div className="card-wrap pull-right">
                                                    <div className="card-header">
                                                        <h3>
                                                            {reelapps.success_projects >= 0 ?
                                                                reelapps.success_projects :
                                                                <i className="fa fa-spinner fa-spin" />}
                                                        </h3>
                                                        <h4><b> Success Projects </b></h4>
                                                    </div>
                                                </div>
                                                <div className="card-chart">
                                                    <div id="chart-3" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12" name="failed_projects" onClick={handleClick}>
                                        <Link to="">
                                            <div className="card card-sales-widget card-bg-orange-gradient">
                                                <div className="card-icon shadow-primary bg-hibiscus">
                                                    <i className="fas fa-user" />
                                                </div>
                                                <div className="card-wrap pull-right">
                                                    <div className="card-header">
                                                        <h3>
                                                            {reelapps.failed_projects >= 0 ?
                                                                reelapps.failed_projects :
                                                                <i className="fa fa-spinner fa-spin" />}
                                                        </h3>
                                                        <h4><b> Failed Projects </b></h4>
                                                    </div>
                                                </div>
                                                <div className="card-chart">
                                                    <div id="chart-3" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12" name="social_accounts" onClick={handleClick}>
                                        <Link to="">
                                            <div className="card card-sales-widget card-bg-orange-gradient">
                                                <div className="card-icon shadow-primary bg-hibiscus">
                                                    <i className="fas fa-user" />
                                                </div>
                                                <div className="card-wrap pull-right">
                                                    <div className="card-header">
                                                        <h3>
                                                            {reelapps.social_accounts >= 0 ?
                                                                reelapps.social_accounts :
                                                                <i className="fa fa-spinner fa-spin" />}
                                                        </h3>
                                                        <h4><b> Social Accounts </b></h4>
                                                    </div>
                                                </div>
                                                <div className="card-chart">
                                                    <div id="chart-3" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12" name="integrations" onClick={handleClick}>
                                        <Link to="">
                                            <div className="card card-sales-widget card-bg-orange-gradient">
                                                <div className="card-icon shadow-primary bg-hibiscus">
                                                    <i className="fas fa-user" />
                                                </div>
                                                <div className="card-wrap pull-right">
                                                    <div className="card-header">
                                                        <h3>
                                                            {reelapps.integrations >= 0 ?
                                                                reelapps.integrations :
                                                                <i className="fa fa-spinner fa-spin" />}
                                                        </h3>
                                                        <h4><b> Integrations </b></h4>
                                                    </div>
                                                </div>
                                                <div className="card-chart">
                                                    <div id="chart-3" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                </div>


                                {/* Reelapps Users */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h6 className="text-left stats-h6">Aggregate Users</h6>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="card card-sales-widget card-bg-blue-gradient">
                                            <div className="card-icon shadow-primary bg-blue">
                                                <i className="fas fa-user-plus" />
                                            </div>

                                            <div className="card-wrap pull-right">
                                                <div className="card-header">
                                                    <h3>{appTotalCount.total_users ?
                                                        appTotalCount.total_users : <i className="fa fa-spinner fa-spin" />}
                                                    </h3>
                                                    <h4><b>Reelapps Total Users</b> </h4>
                                                </div>
                                            </div>
                                            <div className="card-chart">
                                                <div id="chart-1" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="card card-sales-widget card-bg-yellow-gradient">
                                            <div className="card-icon shadow-primary bg-warning">
                                                <i className="fas fa-user-check" />
                                            </div>
                                            <div className="card-wrap pull-right">
                                                <div className="card-header">
                                                    <h3>
                                                        {appTotalCount.active_users ?
                                                            appTotalCount.active_users : <i className="fa fa-spinner fa-spin" />}
                                                    </h3>
                                                    <h4> <b>Reelapps Active Users </b></h4>
                                                </div>
                                            </div>
                                            <div className="card-chart">
                                                <div id="chart-2" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="card card-sales-widget card-bg-orange-gradient">
                                            <div className="card-icon shadow-primary bg-hibiscus">
                                                <i className="fas fa-user-alt-slash" />
                                            </div>
                                            <div className="card-wrap pull-right">
                                                <div className="card-header">
                                                    <h3>
                                                        {appTotalCount.disable_users ?
                                                            appTotalCount.disable_users :
                                                            <i className="fa fa-spinner fa-spin" />}
                                                    </h3>
                                                    <h4><b>Reelapps Disabled Users </b></h4>
                                                </div>
                                            </div>
                                            <div className="card-chart">
                                                <div id="chart-3" />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* App Users Status */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h6 className="text-left stats-h6">Admins</h6>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12" name="total_admins" onClick={handleClick}>
                                        <Link to={'/view-admin'}>
                                            <div className="card card-sales-widget card-bg-blue-gradient">
                                                <div className="card-icon shadow-primary bg-blue">
                                                    <i className="fas fa-user-plus" />
                                                </div>

                                                <div className="card-wrap pull-right">
                                                    <div className="card-header">
                                                        <h3>{appUsers.total_users ?
                                                            appUsers.total_users : <i className="fa fa-spinner fa-spin" />}
                                                        </h3>
                                                        <h4><b>Total Admins</b> </h4>
                                                    </div>
                                                </div>
                                                <div className="card-chart">
                                                    <div id="chart-1" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12" name="active_admins" onClick={(e) => { handleClick(e) }}>
                                        <Link to={'/view-admin'}>
                                            <div className="card card-sales-widget card-bg-yellow-gradient">
                                                <div className="card-icon shadow-primary bg-yellow">
                                                    <i className="fas fa-user-check" />
                                                </div>

                                                <div className="card-wrap pull-right">
                                                    <div className="card-header">
                                                        <h3>{appUsers.active_users ?
                                                            appUsers.active_users : <i className="fa fa-spinner fa-spin" />}
                                                        </h3>
                                                        <h4><b>Active Admins</b> </h4>
                                                    </div>
                                                </div>
                                                <div className="card-chart">
                                                    <div id="chart-1" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12" name="disable_admins" onClick={(e) => { handleClick(e) }}>
                                        <Link to={'/view-admin'}>
                                            <div className="card card-sales-widget card-bg-orange-gradient">
                                                <div className="card-icon shadow-primary bg-hibiscus">
                                                    <i className="fas fa-user-plus" />
                                                </div>

                                                <div className="card-wrap pull-right">
                                                    <div className="card-header">
                                                        <h3>{appUsers.disable_users ?
                                                            appUsers.disable_users : <i className="fa fa-spinner fa-spin" />}
                                                        </h3>
                                                        <h4><b>Disable Admins</b> </h4>
                                                    </div>
                                                </div>
                                                <div className="card-chart">
                                                    <div id="chart-1" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12" name="root_level" onClick={(e) => { handleClick(e) }}>
                                        <Link to={'/view-admin'}>
                                            <div className="card card-sales-widget card-bg-blue-gradient">
                                                <div className="card-icon shadow-primary bg-blue">
                                                    <i className="fas fa-user-plus" />
                                                </div>

                                                <div className="card-wrap pull-right">
                                                    <div className="card-header">
                                                        <h3>{appUsers.root_users ?
                                                            appUsers.root_users : <i className="fa fa-spinner fa-spin" />}
                                                        </h3>
                                                        <h4><b>Root Level</b> </h4>
                                                    </div>
                                                </div>
                                                <div className="card-chart">
                                                    <div id="chart-1" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12" name="admin_level" onClick={(e) => { handleClick(e) }}>
                                        <Link to={'/view-admin'}>
                                            <div className="card card-sales-widget card-bg-blue-gradient">
                                                <div className="card-icon shadow-primary bg-blue">
                                                    <i className="fas fa-user-plus" />
                                                </div>

                                                <div className="card-wrap pull-right">
                                                    <div className="card-header">
                                                        <h3>{appUsers.admin_users ?
                                                            appUsers.admin_users : <i className="fa fa-spinner fa-spin" />}
                                                        </h3>
                                                        <h4><b>Admin Level</b> </h4>
                                                    </div>
                                                </div>
                                                <div className="card-chart">
                                                    <div id="chart-1" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>


                                {/* Applications Status */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h6 className="text-left stats-h6">Applications</h6>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12" name="total_applications" onClick={(e) => { handleClick(e) }}>
                                        <Link to={'/view-application'}>
                                            <div className="card card-sales-widget card-bg-blue-gradient">
                                                <div className="card-icon shadow-primary bg-blue">
                                                    <i className="fas fa-user-plus" />
                                                </div>

                                                <div className="card-wrap pull-right">
                                                    <div className="card-header">
                                                        <h3>{appUsers.total_apps ?
                                                            appUsers.total_apps : <i className="fa fa-spinner fa-spin" />}
                                                        </h3>
                                                        <h4><b>Total Applications</b> </h4>
                                                    </div>
                                                </div>
                                                <div className="card-chart">
                                                    <div id="chart-1" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12" name="active_applications" onClick={(e) => { handleClick(e) }}>
                                        <Link to={'/view-application'}>
                                            <div className="card card-sales-widget card-bg-yellow-gradient">
                                                <div className="card-icon shadow-primary bg-yellow">
                                                    <i className="fas fa-user-check" />
                                                </div>

                                                <div className="card-wrap pull-right">
                                                    <div className="card-header">
                                                        <h3>{appUsers.active_apps ?
                                                            appUsers.active_apps : <i className="fa fa-spinner fa-spin" />}
                                                        </h3>
                                                        <h4><b>Active Applications</b> </h4>
                                                    </div>
                                                </div>
                                                <div className="card-chart">
                                                    <div id="chart-1" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12" name="disabled_applications" onClick={(e) => { handleClick(e) }}>
                                        <Link to={'/view-application'}>
                                            <div className="card card-sales-widget card-bg-orange-gradient">
                                                <div className="card-icon shadow-primary bg-hibiscus">
                                                    <i className="fas fa-user-alt-slash" />
                                                </div>

                                                <div className="card-wrap pull-right">
                                                    <div className="card-header">
                                                        <h3>{appUsers.disable_apps !== '' ?
                                                            appUsers.disable_apps : <i className="fa fa-spinner fa-spin" />}
                                                        </h3>
                                                        <h4><b>Disabled Applications</b> </h4>
                                                    </div>
                                                </div>
                                                <div className="card-chart">
                                                    <div id="chart-1" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>


                            </section>

                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Stats;
