import axios from "axios";
import store from "../store";
import { baseURL } from "../../Global/Global";

/**
 * This function will update Admin Actions log
 * */
export const adminAction = (actionData) =>{
    axios({
        method: 'POST',
        url: `${baseURL}add-admin-action`,
        data: {
            action: actionData.action,
            app_id: store.getState().app.currentApp.id,
            user_id: actionData.user_id,
            reason: actionData.reason,
            user_email: actionData.user_email,
            data: actionData.json_data !== '' ? actionData.json_data : null
        },
        headers: {'Content-Type': 'application/json','Authorization': 'Bearer '+store.getState().auth.token }
    }).then(res=>{
    }).catch(error=>{
        console.log(error);
    })
};
