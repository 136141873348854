import React from 'react';
import './App.css';
import 'react-summernote/dist/react-summernote.css';
import { BrowserRouter as Router, Route, Switch, } from 'react-router-dom'
import Dashboard from './components/dashboard/Dashboard'
import Login from './components/login/Login'
import ResetPassword from './components/resetPassword/ResetPassword'
import ForgetPassword from './components/forgetPassword/ForgetPassword'
import Navbar from './components/navbar/Navbar'
import Sidebar from './components/sidebar/Sidebar'
import Footer from './components/footer/Footer'
import Profile from './components/profile/Profile'
import ThemeSettings from './components/themesetting/ThemeSettings'
import AddUser from './components/addUser/AddUser';
import ViewUser from './components/viewUser/ViewUser';

import { PrivateRoute } from './components/login/PrivateRoute'
import Filter from './components/filter/Filter'
import Loader from "./components/loader/Loader"
import Activity from './components/activity/Activity';
import AddApplication from './components/addApplication/AddApplication';
import ViewApplication from './components/addApplication/ViewApplication';
import EditApplication from "./components/addApplication/EditApplication";
import User from "./components/viewUser/User";
import Toaster from "./components/toaster/Toaster";
import AddFeature from "./components/feature/AddFeature";
import ViewFeature from "./components/feature/ViewFeature";
import AddAdmin from "./components/admin/AddAdmin";
import ViewAdmin from "./components/admin/ViewUser";
import EditUser from "./components/admin/EditUser";
import AddAppTraining from "./training/AddAppTraining";
import ActivityLogs from "./components/activity/ActivityLogs";
import AddVideo from "./components/tutorial/AddVideo";
import ViewVideo from "./components/tutorial/ViewVideo";
import EditVideo from "./components/tutorial/EditVideo";
import AddCategory from "./components/tutorial/AddCategory";
import ViewCategory from "./components/tutorial/ViewCategory";
import EditCategory from "./components/tutorial/EditCategory";
import AddArticle from "./components/tutorial/AddArticle";
import EditArticle from "./components/tutorial/EditArticle";
import ViewArticle from "./components/tutorial/ViewArticle";
import AddFont from "./components/fonts/AddFont";
import ViewFonts from "./components/fonts/ViewFonts";
import EditFont from "./components/fonts/EditFont";
import AddWebinar from "./components/webinar/AddWebinar";
import ViewWebinar from "./components/webinar/ViewWebinar";
import EditWebinar from "./components/webinar/EditWebinar";
import AddWalkthrough from "./components/walkthrough/AddWalkthrough";
import ViewWalkthrough from "./components/walkthrough/ViewWalkthrough";
import EditWalkthrough from "./components/walkthrough/EditWalkthrough";
import FlaggedUsers from './components/viewUser/FlaggedUsers';
import ViewBlackedListEmail from './components/blackedListEmail/ViewBlackedListEmail';
import AddBulkUser from './components/addBulkUser/AddBulkUser';

function App() {
    // const auth = {isAuthenticated: true}
    return (
        <div className="App">
            <Router>
                <div>
                    <Navbar />
                    <Sidebar />
                    <Switch>
                        <PrivateRoute exact path="/dashboard" component={Dashboard} />
                        <PrivateRoute exact path="/" component={Dashboard} />
                        <PrivateRoute exact path="/profile" component={Profile} />
                        <PrivateRoute exact path="/reelapp/add-user" component={AddUser} />
                        <PrivateRoute exact path="/reelapp/add-bulk-user" component={AddBulkUser} />
                        <PrivateRoute exact path="/view-user" component={ViewUser} />
                        <PrivateRoute exact path="/reelapp/user/:id" component={User} />
                        <PrivateRoute exact path="/activity" component={Activity} />
                        <PrivateRoute exact path="/add-application" component={AddApplication} />
                        <PrivateRoute exact path="/view-application" component={ViewApplication} />
                        <PrivateRoute exact path="/edit-application/:id" component={EditApplication} />
                        <PrivateRoute exact path="/add-feature/" component={AddFeature} />
                        <PrivateRoute exact path="/view-feature/" component={ViewFeature} />
                        <PrivateRoute exact path="/add-admin/" component={AddAdmin} />
                        <PrivateRoute exact path="/view-admin/" component={ViewAdmin} />
                        <PrivateRoute exact path="/edit-admin/:id" component={EditUser} />
                        <PrivateRoute exact path="/add-app-tutorial" component={AddAppTraining} />
                        <PrivateRoute exact path="/activity-logs" component={ActivityLogs} />
                        <PrivateRoute exact path="/add-video" component={AddVideo} />
                        <PrivateRoute exact path="/view-video" component={ViewVideo} />
                        <PrivateRoute exact path="/edit-video/:id" component={EditVideo} />
                        <PrivateRoute exact path="/add-category" component={AddCategory} />
                        <PrivateRoute exact path="/view-category" component={ViewCategory} />
                        <PrivateRoute exact path="/edit-category/:id" component={EditCategory} />
                        <PrivateRoute exact path="/add-article" component={AddArticle} />
                        <PrivateRoute exact path="/view-article" component={ViewArticle} />
                        <PrivateRoute exact path="/edit-article/:id" component={EditArticle} />
                        <PrivateRoute exact path="/flagged-user" component={FlaggedUsers} />

                        {/* Fonts */}
                        <PrivateRoute exact path="/add-font" component={AddFont} />
                        <PrivateRoute exact path="/view-fonts" component={ViewFonts} />
                        <PrivateRoute exact path="/edit-font/:id/:name" component={EditFont} />

                        {/* Webinar & Dashboard Videos */}
                        <PrivateRoute exact path="/add-webinar" component={AddWebinar} />
                        <PrivateRoute exact path="/view-webinar" component={ViewWebinar} />
                        <PrivateRoute exact path="/edit-webinar/:id" component={EditWebinar} />
                        <PrivateRoute exact path="/add-walkthrough" component={AddWalkthrough} />
                        <PrivateRoute exact path="/view-walkthrough" component={ViewWalkthrough} />
                        <PrivateRoute exact path="/edit-walkthrough/:id" component={EditWalkthrough} />

                        {/* black list email */}
                        <PrivateRoute exact path="/view-blacklistemail" component={ViewBlackedListEmail} />

                        <Route exact path="/login" component={Login} />
                        <Route exact path="/loader" component={Loader} />
                        <Route exact path="/filter" component={Filter} />
                        <Route exact path="/forget-password/" component={ForgetPassword} />
                        <Route exact path="/reset-password/:token" component={ResetPassword} />
                    </Switch>
                    <ThemeSettings />
                    <Toaster />
                    <Footer />
                </div>
            </Router>
        </div>
    );
}

export default App;
