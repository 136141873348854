import React, { useEffect, useState } from 'react'
import axios from "axios";
import { useSelector } from "react-redux";
import TablePagination from '../pagination/TablePagination';
import { Link } from "react-router-dom";
import ReactTable from 'react-data-table-component';
import { useDispatch } from "react-redux";
import { baseURL } from '../../Global/Global';

const ViewBlackedListEmail = () => {

    const currentApp = useSelector(state => state.app.currentApp);
    const [user, setUser] = useState();
    const dispatch = useDispatch();

    const [q, setQ] = useState('');

    const search = (users) => {
        return users.filter(
            user => user.email ? user.email.toLowerCase().indexOf(q.toLowerCase()) > -1 : ''
        );
    };

    const getQ = (e) => {
        setQ(e.target.value)
    };


    const fetchEmail = () => {
        axios({
            method: 'POST',
            url: `${baseURL}api/fetch-blackListed-emails`,
        }).then(res => {
            if (res.data.status === true) {
                setUser(res.data.data);
            }

        }).catch(error => {
            console.log(error);
        })
    };

    const functionUnBlock = (type, id, email, reason) => {
        if (window.confirm("Are you sure, you want to Unblock this user ?")) {
            axios({
                method: 'POST',
                url: `${baseURL}api/blackList-email`,
                data: { email, type, id, reason, status: false, },
                headers: { 'Content-type': 'application/json' }
            }).then(res => {
                if (res.data.status === true) {
                    dispatch({ type: 'SUCCESS', payload: res.data.message });
                    fetchEmail();
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }


    const columns = [
        {
            name: 'Id',
            selector: row => row.Id,
            sortable: true,
            grow: 1,
            wrap: true
        },
        {
            name: 'User Id',
            selector: row => row.User_id,
            sortable: true,
            grow: 5,
            wrap: true
        },
        {
            name: 'Email',
            selector: row => row.Email,
            sortable: true,
            grow: 5,
            wrap: true
        },
        {
            name: 'Type',
            selector: row => row.Type,
            sortable: true,
            grow: 3,
            wrap: true

        },
        {
            name: 'Status',
            selector: row => row.Status,
            grow: 6,
            wrap: true
        },
        {
            name: 'Reason',
            selector: row => row.Reason,
            grow: 6,
            wrap: true
        },
        {
            name: 'Created',
            selector: row => row.Created,
            sortable: true,
            grow: 5,
            wrap: true
        },
        {
            name: 'Modified',
            selector: row => row.Modified,
            button: true,
            grow: 1,
            wrap: true
        },
        {
            name: 'Action',
            selector: row => row.Action,
            button: true,
            grow: 1,
            wrap: true
        }

    ];

    const data = []
    if (user) {
        search(user).forEach((ele, index) => {
            let myData = {
                Id: ele.id,
                User_id: ele.user_id,
                Email: ele.email,
                Type: ele.type,
                Status: ele.status,
                Reason: ele.reason,
                Created: ele.created,
                Modified: ele.modified,
                // Action: <button type="button" class="btn btn-primary" onChange={(e) => { if (window.confirm(blacklistEmail.status === false ? 'Are you sure, you want to blacklist this user ?' : 'Are you sure, you want to whitelist this user ?')) { functionBlackListEmail(e) } }}>Unblock</button>
                Action: <button type="button" class="btn btn-primary" onClick={() => { functionUnBlock(ele.type, ele.user_id, ele.email, ele.reason) }}>Unblock</button>
            }
            data.push(myData);
        })
    }

    useEffect(() => {
        fetchEmail();
    }, []);


    return (
        <section>
            <div id="app">
                <div className="main-wrapper main-wrapper-1">
                    <div className="navbar-bg" />

                    {/* Main Content */}
                    <div className="main-content">
                        <section className="section">
                            <div className="section-header">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div className="section-header-breadcrumb-content">
                                            <h1>Black List Users</h1>
                                            <div className="section-header-breadcrumb">
                                                <div className="breadcrumb-item active"><a href="#"><i className="fas fa-home" /></a></div>
                                                <div className="breadcrumb-item"><a href="#">ReelApps</a></div>
                                                <div className="breadcrumb-item"><a href="#">Users</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header d-flex justify-content-between">
                                                <h4>Black List Users</h4>
                                                <div className="search__user">
                                                    <input type="text" className="form-control" value={q} onChange={(e) => getQ(e)} placeholder="Search here..." />
                                                </div>
                                            </div>

                                            <div className="card-body">
                                                <div className="table-responsive">
                                                    <ReactTable
                                                        columns={columns}
                                                        data={data}
                                                        selectableRows
                                                        pagination
                                                        progressPending={user ? false : true}
                                                        responsive={true}
                                                        highlightOnHover={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div >
        </section >
    )
}

export default ViewBlackedListEmail