import React, { useState, useRef } from 'react'
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from "react-redux";
import ChangeDetails from "./ChangeDetails";
import ChangeEmail from "./ChangeEmail";
import UpdatePassword from './UpdatePassword'
import Applications from "./Applications";
import { appUserStatus } from "../../store/actions/reelAppsActions";
import Campaign from './Campaign';
import { appUserDeleted } from "../../store/actions/reelAppsActions";
import { appUserSuspend } from "../../store/actions/reelAppsActions";
import { appUserWhitelist } from "../../store/actions/reelAppsActions";
import { Link } from "react-router-dom";
import { useEffect } from 'react';
import axios from "axios"
import { baseURL } from '../../Global/Global';

const User = (props) => {
    const params = props.match.params;
    const dispatch = useDispatch();
    const users = useSelector(state => state.reelapps.users);
    const auth = useSelector(state => state.auth);
    const apps = useSelector(state => state.app.apps);
    const currentApp = useSelector((state) => state.app.currentApp);

    const modalPopup = useRef();
    const modalPopupClose = useRef();
    // const [loader, setLoader] = useState(true);
    const targetId = String(params.id);


    const us = users.find(user => {
        const userIdStr = String(user?.user_id);
        const idStr = String(user?.id);
        return userIdStr === targetId || idStr === targetId;
    });


    const [user, setUser] = useState(us);

    useEffect(() => {
        if (us) {
            setUser(us)
        }
    }, [users, user])


    const [userStatus, setUserStatus] = useState({
        secret_key: useSelector(state => state.app.currentApp.secret_key),
        email: user?.email,
        status: currentApp.name === "VidCuratorFx 2.0" || currentApp.name === "VidOptimizeNeos" ? user?.status === '0' ? "1" : "0" : user?.status,
        id: user?.user_id ? user?.user_id : user?.id,
        reason: null
    });
    useEffect(() => {
        if (user) {
            setUserStatus(prevStatus => ({
                ...prevStatus,
                email: user?.email,
                status: user?.status,
                id: user.user_id ? user?.user_id : user?.id
            }));
        }
    }, [user]);
    // useEffect(() => {
    //     if (user) {
    //         setUserStatus({
    //             secret_key: useSelector(state => state.app.apps.secret_key),
    //             email: user?.email,
    //             status: user?.status,
    //             id: user?.user_id ? user?.user_id : user?.id,
    //             reason: null
    //         })
    //     }
    // }, [user])



    const [userDeleted, setUserDeleted] = useState({
        secret_key: useSelector(state => state.app.currentApp.secret_key),
        email: user?.email,
        deleted: user?.deleted,
        id: user?.user_id ? user?.user_id : user?.id,
        reason: null
    });
    const [userSuspend, setUserSuspend] = useState({
        secret_key: useSelector(state => state.app.currentApp.secret_key),
        email: user?.email,
        is_suspend: user?.is_suspend,
        id: user?.user_id ? user?.user_id : user?.id,
        reason: user?.suspend_reason
    });

    const [blacklistEmail, setBlacklistEmail] = useState({
        email: user?.email,
        status: "",
        type: user?.type,
        id: auth.user?.user_id ? auth.user?.user_id : auth.user?.id,
        reason: user?.reason
    });

    const [whitelist, setWhitelist] = useState({
        secret_key: useSelector(state => state.app.currentApp.secret_key),
        is_whitelist: user?.is_whitelist,
        id: user?.user_id ? user?.user_id : user?.id,
    });

    const functionWhiteList = (e) => {
        if (whitelist.is_whitelist === "1") {
            setWhitelist({ ...whitelist, is_whitelist: "0" });
            dispatch(appUserWhitelist({ ...whitelist, is_whitelist: "0" }));
        } else {
            setWhitelist({ ...whitelist, is_whitelist: "1" });
            dispatch(appUserWhitelist({ ...whitelist, is_whitelist: "1" }));
        }
    }
    console.log(currentApp.name, "currentApp")
    const enableDisable = (e) => {
        let message = window.prompt('Please Enter a Valid Reason');
        if (currentApp.name === "VidOptimizeNeos" || currentApp.name === "VidCuratorFX 2.0") {
            if (message !== '' && message.length > 10) {
                if (userStatus.status === "0") {
                    dispatch(appUserStatus({ ...userStatus, status: "1", reason: message }, setUserStatus));
                }
                else {
                    dispatch(appUserStatus({ ...userStatus, status: "0", reason: message }, setUserStatus));
                }
            } else {
                alert("please enter minimum 10 character")
            }
        } else {
            if (message !== '' && message.length > 10) {
                if (userStatus.status === "1") {
                    dispatch(appUserStatus({ ...userStatus, status: "0", reason: message }, setUserStatus));
                }
                else {
                    dispatch(appUserStatus({ ...userStatus, status: "1", reason: message }, setUserStatus));
                }
            } else {
                alert("please enter minimum 10 character")
            }
        }
    };

    const deleteUser = (e) => {
        let message = window.prompt('Please Enter a Valid Reason');

        if (message !== '' && message.length > 10) {
            if (userDeleted.deleted === "1") {
                setUserDeleted({ ...userDeleted, deleted: "0" });
                dispatch(appUserDeleted({ ...userDeleted, deleted: "0", reason: message }));
            } else {
                setUserDeleted({ ...userDeleted, deleted: "1" });
                dispatch(appUserDeleted({ ...userDeleted, deleted: "1", reason: message }));
            }
        }
    };

    const suspendUser = (e) => {
        if (userSuspend.is_suspend === "1") {

            setUserSuspend({ ...userSuspend, is_suspend: "0", reason: "" });
            dispatch(appUserSuspend({ ...userSuspend, is_suspend: "0", reason: "" }));
        } else {
            let message = window.prompt('Please Enter a Valid Reason');
            if (message && message.length > 40) {

                setUserSuspend({ ...userSuspend, is_suspend: "1", reason: message });
                dispatch(appUserSuspend({ ...userSuspend, is_suspend: "1", reason: message }));
            } else {
                alert("Please enter minimum 40 character")
            }
        }
    };

    const reasonOnChange = (e) => {
        setBlacklistEmail({ ...blacklistEmail, reason: e.target.value })
    }
    const selectType = (e) => {
        setBlacklistEmail({ ...blacklistEmail, type: e.target.value })
    }


    const functionBlackListEmail = (e) => {
        if (blacklistEmail.status === false) {
            modalPopup.current.click();
        } else {
            const { email, type, id, reason } = blacklistEmail
            axios({
                method: 'POST',
                url: `${baseURL}api/blackList-email`,
                data: { email, type, id, reason, status: false },
                headers: { 'Content-type': 'application/json' }
            }).then(res => {
                if (res.data.status === true) {
                    dispatch({ type: 'SUCCESS', payload: res.data.message });
                    modalPopupClose.current.click();
                    fetchBlacklistEmail(user.email)
                }
            }).catch(error => {
                console.log(error)
            })
        }
    };

    const functionBlockEmail = () => {
        const { email, type, id, reason } = blacklistEmail
        if (reason !== '' && reason.length > 40) {
            axios({
                method: 'POST',
                url: `${baseURL}api/blackList-email`,
                data: { email, type, id, reason, status: true },
                headers: { 'Content-type': 'application/json' }
            }).then(res => {
                if (res.data.status === true) {
                    dispatch({ type: 'SUCCESS', payload: res.data.message });
                    modalPopupClose.current.click();
                    fetchBlacklistEmail(user.email)
                }
            }).catch(error => {
                console.log(error)
            })
        } else {
            modalPopupClose.current.click();
            fetchBlacklistEmail(user.email)
        }
    }
    const fetchBlacklistEmail = (email) => {
        axios({
            method: 'POST',
            url: `${baseURL}api/check-email`,
            data: { email: email },
            headers: { 'Content-type': 'application/json' }
        }).then(res => {
            if (res.data.status === true) {
                setBlacklistEmail({ ...blacklistEmail, status: true, reason: res.data.reason })
            } else {
                setBlacklistEmail({ ...blacklistEmail, status: false, reason: res.data.reason })
            }
        }).catch(error => {
            console.log(error)
        })
    }

    // remove this function user less
    // const fetchWhiteListEmail = (email) => {
    //     axios({
    //         method: 'POST',
    //         url: "https://adminserver.reelapps.io/api/checkwhitelist",
    //         data: { email: email },
    //         headers: { 'Content-type': 'application/json' }
    //     }).then(res => {
    //         if (res.data.status === true) {
    //             setWhitelist({ ...whitelist, is_whitelist: "1" })
    //         } else {
    //             setWhitelist({ ...whitelist, is_whitelist: "0" })
    //         }
    //     }).catch(error => {
    //         console.log(error)
    //     })
    // }

    useEffect(() => {
        fetchBlacklistEmail(user.email)
        // fetchWhiteListEmail(user.email)
    }, []);

    return (
        <div>

            {/* <!-- Button trigger modal --> */}
            <button ref={modalPopup} type="button" style={{ "display": "none" }} class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
                Launch demo modal
            </button>

            {/* <!-- Modal popup --> */}
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Blacklisting the user will deactivate his account across All other Application</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            {blacklistEmail.status === false ?
                                <div class="form-group">
                                    <label for="formGroupExampleInput">Reason</label>
                                    <input type="text" class="form-control" id="formGroupExampleInput" placeholder="Enter valid Reason" defaultValue={blacklistEmail.reason} onChange={reasonOnChange} />
                                </div> : ""}
                            <select class="custom-select" id="inputGroupSelect01" onChange={selectType}>
                                <option selected>Choose...</option>
                                <option value="all">All</option>
                                <option value="realapp">Real App</option>
                            </select>
                        </div>
                        <div class="modal-footer">
                            <button ref={modalPopupClose} type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" onClick={functionBlockEmail}>Send</button>
                        </div>
                    </div>
                </div>
            </div>


            <Helmet>
                <title> Super-Admin | Profile</title>
            </Helmet>
            <section>
                <div className="main-content" style={{ minHeight: '694px' }}>
                    <section className="section">
                        <div className="section-header">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                    <div className="section-header-breadcrumb-content">
                                        <h1>Profile</h1>
                                        <div className="section-header-breadcrumb">
                                            <div className="breadcrumb-item active"><a href="#"><i
                                                className="fas fa-home" /></a></div>
                                            <div className="breadcrumb-item"><a href="#">ReelApps</a></div>
                                            <div className="breadcrumb-item"><a href="#">User</a></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">

                                </div>
                            </div>
                            <div className="row">
                                {/* all alerts */}
                                {currentApp.name === "VidCuratorFx 2.0" || currentApp.name === "VidOptimizeNeos" ?
                                    userStatus?.status === "1" ?
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                            <div className="alert alert-warning alert-dismissible show fade">
                                                <div className="alert-body">
                                                    <button className="close" data-dismiss="alert">
                                                        <span>×</span>
                                                    </button>
                                                    <i className="fa fa-user-slash mr-2 " />This user is disabled.
                                                </div>
                                            </div>
                                        </div> : ''
                                    :
                                    userStatus?.status === "0" ?
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                            <div className="alert alert-warning alert-dismissible show fade">
                                                <div className="alert-body">
                                                    <button className="close" data-dismiss="alert">
                                                        <span>×</span>
                                                    </button>
                                                    <i className="fa fa-user-slash mr-2 " />This user is disabled.
                                                </div>
                                            </div>
                                        </div> : ''
                                }


                                {
                                    userDeleted.deleted === "1" ?
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                            <div className="alert alert-danger alert-dismissible show fade">
                                                <div className="alert-body">
                                                    <button className="close" data-dismiss="alert">
                                                        <span>×</span>
                                                    </button>
                                                    <i className="fa fa-user-times mr-2 " />This user is Deleted.
                                                </div>
                                            </div>
                                        </div>
                                        : ''
                                }
                                {
                                    userSuspend.is_suspend === "1" ?
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                            <div className="alert alert-danger alert-dismissible show fade">
                                                <div className="alert-body">
                                                    <button className="close" data-dismiss="alert">
                                                        <span>×</span>
                                                    </button>
                                                    <i className="fa fa-user-times mr-2 " /><strong>This User Is Suspended.</strong>    {"message:" + userSuspend.reason}
                                                </div>
                                            </div>
                                        </div>
                                        : ''
                                }
                                {
                                    whitelist.is_whitelist === "1" ?
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                            <div className="alert alert-success alert-dismissible show fade">
                                                <div className="alert-body">
                                                    <button className="close" data-dismiss="alert">
                                                        <span>×</span>
                                                    </button>
                                                    <i className="fa fa-user-times mr-2" />This User Is Whitelist. {whitelist.reason}
                                                </div>
                                            </div>
                                        </div>
                                        : ''
                                }
                                {
                                    blacklistEmail.status === true ?
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                            <div className="alert alert-danger alert-dismissible show fade">
                                                <div className="alert-body">
                                                    <button className="close" data-dismiss="alert">
                                                        <span>×</span>
                                                    </button>
                                                    <i className="fa fa-user-times mr-2 " /><strong>This User Is Blacklisted.</strong>    {"message:" + blacklistEmail.reason}
                                                </div>
                                            </div>
                                        </div>
                                        : ''
                                }
                            </div>
                        </div>
                        <div className="section-body">
                            <div className="row mt-sm-4">
                                <div className="col-12 col-md-12 col-lg-8">
                                    <div className="card">
                                        <div className="padding-20">
                                            <ul className="nav nav-pills mb-1" id="myTab2" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link active" id="profile-tab2" data-toggle="tab"
                                                        href="#settings" role="tab" aria-selected="false">Edit
                                                        Profile</a>
                                                </li>
                                            </ul>
                                            <div className="tab-content tab-bordered" id="myTab3Content">
                                                <div className="tab-pane fade show active" id="settings" role="tabpanel"
                                                    aria-labelledby="profile-tab2">
                                                    <div className="card-body">
                                                        <ChangeDetails user={user} />
                                                        <ChangeEmail user={user} />
                                                        <UpdatePassword user={user} />
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-12 col-lg-4">

                                    <div className="card mb-2">
                                        <div className="row author-box">
                                            <img alt="image" src={user?.profile_image ? user?.profile_image : "../../assets/images/default_profile.png"}
                                                className="rounded-circle author-box-picture box-center mt-4" />
                                        </div>
                                        <h5>  {user?.name ? user?.name : user?.first_name} </h5>
                                        <h6><i className="fa fa-envelope" /> {user?.email} </h6>
                                        {
                                            user?.parent !== '' ? <h6>Parent: {user?.parent} </h6> : ''
                                        }


                                        <div className="card-body">
                                            {currentApp.name === "VidCuratorFx 2.0" || currentApp.name === "VidOptimizeNeos" ?
                                                <div className="pretty p-switch">
                                                    <input type="checkbox" onChange={(e) => { if (window.confirm(userStatus.status === "0" ? 'Are You sure? Disable User' : 'Are You sure? Active User')) { enableDisable(e) } }}
                                                        checked={userStatus.status === "0"} />
                                                    <div className="state p-warning">
                                                        <label>{userStatus.status === "1" ? "Disabled" : "Active"}</label>
                                                    </div>
                                                </div> :
                                                <div className="pretty p-switch">
                                                    <input type="checkbox" onChange={(e) => { if (window.confirm(userStatus.status === "1" ? 'Are You sure? Disable User' : 'Are You sure? Active User')) { enableDisable(e) } }}
                                                        checked={userStatus.status === "1"} />
                                                    <div className="state p-warning">
                                                        <label>{userStatus.status === "0" ? "Disabled" : "Active"}</label>
                                                    </div>
                                                </div>}
                                            <div className="pretty p-switch">
                                                <input type="checkbox" onChange={(e) => { if (window.confirm(userDeleted.deleted === "0" ? 'Are You sure? Delete User' : 'Are You sure? Enable User')) { deleteUser(e) } }}
                                                    checked={userDeleted.deleted === "1"} />
                                                <div className="state p-danger">
                                                    <label>{userDeleted.deleted === "1" ? "Deleted" : "Delete"}</label>
                                                </div>
                                            </div>
                                            <div className="pretty p-switch">
                                                <input type="checkbox" onChange={(e) => { if (window.confirm(userSuspend.is_suspend === "0" ? 'Are you sure, you want to suspend this user ?' : 'Are you sure, you want to unsuspend this user ?')) { suspendUser(e) } }}
                                                    checked={userSuspend.is_suspend === "1"} />
                                                <div className="state p-warning">
                                                    <label>{userSuspend.is_suspend === "1" ? "Unsuspend" : "Suspend"}</label>
                                                </div>
                                            </div>
                                            <div className="pretty p-switch">
                                                <input type="checkbox" onChange={(e) => { if (window.confirm(blacklistEmail.status === false ? 'Are you sure, you want to blacklist this user ?' : 'Are you sure, you want to Unblock this user ?')) { functionBlackListEmail(e) } }}
                                                    checked={blacklistEmail.status === true} />
                                                <div className="state p-warning">
                                                    <label>{blacklistEmail.status === true ? "Unblock" : "Blacklist"}</label>
                                                </div>
                                            </div>
                                            <div className="pretty p-switch">
                                                <input type="checkbox" onChange={(e) => { if (window.confirm(whitelist.is_whitelist === "0" ? 'Are you sure, you want to whitelist this user ?' : 'Are you sure, you want to remove from whitelist this user ?')) { functionWhiteList(e) } }}
                                                    checked={whitelist.is_whitelist === "1"} />
                                                <div className="state p-warning">
                                                    <label>{whitelist.is_whitelist === "1" ? "Removewhitelist" : "Whitelist"}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="card">
                                        <div className="card-header">
                                            <h4>Reel-Apps Accounts</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>check the box will create an account in that app</p>
                                            <div className="py-4">
                                                {
                                                    apps.map(app => (
                                                        <Applications key={app.id} app={app} user={user} />
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header">
                                            <h4>Reel-Apps Master Login</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="py-4">
                                                {
                                                    apps.map(app => (
                                                        app.endpoints.master_login ?
                                                            <a className="login-a" target="_blank" href={app.endpoints.master_login + '?email=' + user?.email + '&token=' + app.encrypt_key}>
                                                                <p className="clearfix text-left">
                                                                    <img className='img-responsive pr-2' src={app.logo} width="35" />
                                                                    {app.name}
                                                                </p>
                                                            </a>
                                                            :
                                                            <Link className="login-a">
                                                                <p className="clearfix text-left text-muted">
                                                                    <img className="img-responsive pr-2 grayscale" src={app.logo} width="35" />
                                                                    {app.name}
                                                                </p>
                                                            </Link>

                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>



                                </div>
                            </div>

                        </div>
                        <div>
                            <Campaign user={user} />
                        </div>

                    </section>
                    <div className="settingSidebar">
                        <a onClick={e => e.preventDefault()} className="settingPanelToggle"> <i className="fas fa-adjust" />
                        </a>
                        <div className="settingSidebar-body ps-container ps-theme-default" tabIndex={2}
                            style={{ overflow: 'hidden', outline: 'none' }}>
                            <div className=" fade show active">
                                <div className="setting-panel-header">Theme Customizer</div>
                                <div className="p-15 border-bottom">
                                    <h6 className="font-medium m-b-10">Theme Layout</h6>
                                    <div className="selectgroup layout-color w-50">
                                        <label> <span className="control-label p-r-20">Light</span>
                                            <input type="radio" name="custom-switch-input" defaultValue={1}
                                                className="custom-switch-input" defaultChecked /> <span
                                                className="custom-switch-indicator" />
                                        </label>
                                    </div>
                                    <div className="selectgroup layout-color  w-50">
                                        <label> <span className="control-label p-r-20">Dark&nbsp;</span>
                                            <input type="radio" name="custom-switch-input" defaultValue={2}
                                                className="custom-switch-input" /> <span
                                                className="custom-switch-indicator" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="p-15 border-bottom">
                                <h6 className="font-medium m-b-10">Sidebar Colors</h6>
                                <div className="sidebar-setting-options">
                                    <ul className="sidebar-color list-unstyled mb-0">
                                        <li title="white">
                                            <div className="white" />
                                        </li>
                                        <li title="blue">
                                            <div className="blue" />
                                        </li>
                                        <li title="coral">
                                            <div className="coral" />
                                        </li>
                                        <li title="purple" className="active">
                                            <div className="purple" />
                                        </li>
                                        <li title="allports">
                                            <div className="allports" />
                                        </li>
                                        <li title="barossa">
                                            <div className="barossa" />
                                        </li>
                                        <li title="fancy">
                                            <div className="fancy" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="p-15 border-bottom">
                                <h6 className="font-medium m-b-10">Theme Colors</h6>
                                <div className="theme-setting-options">
                                    <ul className="choose-theme list-unstyled mb-0">
                                        <li title="white" className="active">
                                            <div className="white" />
                                        </li>
                                        <li title="blue">
                                            <div className="blue" />
                                        </li>
                                        <li title="coral">
                                            <div className="coral" />
                                        </li>
                                        <li title="purple">
                                            <div className="purple" />
                                        </li>
                                        <li title="allports">
                                            <div className="allports" />
                                        </li>
                                        <li title="barossa">
                                            <div className="barossa" />
                                        </li>
                                        <li title="fancy">
                                            <div className="fancy" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="p-15 border-bottom">
                                <h6 className="font-medium m-b-10">Layout Options</h6>
                                <div className="theme-setting-options">
                                    <label> <span className="control-label p-r-20">Compact
                                        Sidebar Menu</span> <input type="checkbox" name="custom-switch-checkbox"
                                            className="custom-switch-input"
                                            id="mini_sidebar_setting" /> <span
                                            className="custom-switch-indicator" />
                                    </label>
                                </div>
                            </div>
                            <div className="mt-3 mb-3 align-center">
                                <a href="#" className="btn btn-icon icon-left btn-outline-primary btn-restore-theme">
                                    <i className="fas fa-undo" /> Restore Default
                                </a>
                            </div>
                        </div>
                        <div id="ascrail2001" className="nicescroll-rails nicescroll-rails-vr" style={{
                            width: '8px',
                            zIndex: 100,
                            cursor: 'default',
                            position: 'absolute',
                            top: '0px',
                            left: '272px',
                            height: '375px',
                            display: 'block',
                            opacity: 0
                        }}>
                            <div className="nicescroll-cursors" style={{
                                position: 'relative',
                                top: '0px',
                                float: 'right',
                                width: '6px',
                                height: '266px',
                                backgroundColor: 'rgb(66, 66, 66)',
                                border: '1px solid rgb(255, 255, 255)',
                                backgroundClip: 'padding-box',
                                borderRadius: '5px'
                            }} />
                        </div>
                        <div id="ascrail2001-hr" className="nicescroll-rails nicescroll-rails-hr" style={{
                            height: '8px',
                            zIndex: 100,
                            top: '367px',
                            left: '0px',
                            position: 'absolute',
                            cursor: 'default',
                            display: 'none',
                            width: '272px',
                            opacity: 0
                        }}>
                            <div className="nicescroll-cursors" style={{
                                position: 'absolute',
                                top: '0px',
                                height: '6px',
                                width: '280px',
                                backgroundColor: 'rgb(66, 66, 66)',
                                border: '1px solid rgb(255, 255, 255)',
                                backgroundClip: 'padding-box',
                                borderRadius: '5px',
                                left: '0px'
                            }} />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default User;
