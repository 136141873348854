import React, { useEffect, useState } from 'react'
import axios from "axios";
import { useSelector } from "react-redux";
import TablePagination from '../pagination/TablePagination';
import { Link } from "react-router-dom";
import ReactTable from 'react-data-table-component';


const FlaggedUsers = () => {

    const currentApp = useSelector(state => state.app.currentApp);
    const [user, setUser] = useState();


    const columns = [
        {
            name: 'Id',
            selector: row => row.Id,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.Name,
            sortable: true,
            width: "150PX"
        },
        {
            name: 'Email',
            selector: row => row.Email,
            sortable: true,
            width: "250PX"
        },
        {
            name: 'Total_IP',
            selector: row => row.Total_IP,
            sortable: true,
            width: "50",
        },
        {
            name: 'Client_IP',
            selector: row => row.Client_IP,
            width: "250PX"
        },
        {
            name: 'Created',
            selector: row => row.Created,
            sortable: true,
            width: "150px",
        },
        {
            name: 'Action',
            selector: row => row.Action,
            button: true,
            width: "50",
        },

    ];

    const data = []






    const fetchCampaigns = () => {
        axios({
            method: 'POST',
            url: `${currentApp.domain}/api/flagged_user`,
        }).then(res => {
            if (res.data.status === true) {
                setUser(res.data.data);
            }

        }).catch(error => {
            console.log(error);
        })
    };

    function convertToString(ip) {
        let myArray = [];
        ip.forEach((element) => {
            myArray.push(element.client_ip)
        })
        let unique = myArray.filter((v, i, a) => a.indexOf(v) === i);
        return JSON.stringify(unique);
    }

    const [showPerPage, setShowPerPage] = useState(10);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };

    const [q, setQ] = useState('');

    const search = (users) => {
        return users.filter(
            user => user.name || user.email ? user.name.toLowerCase().indexOf(q.toLowerCase()) > -1 || user.email.toLowerCase().indexOf(q.toLowerCase()) > -1 : ''
        );
    };

    const getQ = (e) => {
        setQ(e.target.value)
    };

    useEffect(() => {
        fetchCampaigns();
    }, []);


    if (user) {
        search(user).forEach((ele, index) => {
            let myData = {
                Id: ele.user_id,
                Name: ele.name,
                Email: ele.email,
                Total_IP: ele.count,
                Client_IP: <textarea className="form-control" style={{ width: "210px" }} value={convertToString(ele.ip)} readOnly={true}></textarea>,
                Created: ele.created,
                Action: <Link to={`/reelapp/user/${ele.user_id}`}> <i className="fa fa-pencil-alt" data-toggle="tooltip" data-original-title="edit user" /></Link>
            }
            data.push(myData);
        })
    }



    return (
        <section>
            <div id="app">
                <div className="main-wrapper main-wrapper-1">
                    <div className="navbar-bg" />

                    {/* Main Content */}
                    <div className="main-content">
                        <section className="section">
                            <div className="section-header">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div className="section-header-breadcrumb-content">
                                            <h1>Flagged Users</h1>
                                            <div className="section-header-breadcrumb">
                                                <div className="breadcrumb-item active"><a href="#"><i className="fas fa-home" /></a></div>
                                                <div className="breadcrumb-item"><a href="#">ReelApps</a></div>
                                                <div className="breadcrumb-item"><a href="#">Users</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header d-flex justify-content-between">
                                                <h4>{currentApp.name} Users</h4>
                                                {/* <div style={{textAlign:"left"}}>
                                                            <button>suspend</button>
                                                        </div> */}
                                                <div className="search__user">
                                                    <input type="text" className="form-control" value={q} onChange={(e) => getQ(e)} placeholder="Search here..." />
                                                </div>
                                            </div>

                                            <div className="card-body">
                                                <div className="table-responsive">

                                                    <ReactTable
                                                        columns={columns}
                                                        data={data}
                                                        selectableRows
                                                        pagination
                                                        progressPending={user ? false : true}
                                                        responsive={true}
                                                        highlightOnHover={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div >
        </section >
    )
}

export default FlaggedUsers