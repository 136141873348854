import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Skeleton from "react-loading-skeleton";
import TablePagination from "../pagination/TablePagination";
import { useSelector } from "react-redux";
import ReactTable from 'react-data-table-component';
import ReactHtmlParser from 'react-html-parser';
import { baseURL } from '../../Global/Global';

const ActivityLogs = () => {

    const auth = useSelector(state => state.auth);
    const [loader, setLoader] = useState({
        isLoading: true
    });
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const [datatable, setDatatable] = useState({
        columns: [
            {
                name: 'ID',
                selector: row => row.id,
                sortable: true,
                grow: 0,
                wrap: true
            },
            {
                name: 'User Email',
                selector: row => row.user_email,
                sortable: true,
                grow: 1,
                wrap: true
            },
            {
                name: 'Controller',
                selector: row => row.controller,
                sortable: true,
                grow: 1,
                wrap: true
            },
            {
                name: 'Method',
                selector: row => row.method,
                sortable: true,
                grow: 0,
                wrap: true

            },
            {
                name: 'IP Address',
                selector: row => row.ip_address,
                grow: 0,
                wrap: true
            },
            {
                name: 'User Agent',
                selector: row => row.user_agent,
                grow: 0,
                wrap: true
            },
            {
                name: 'Created',
                selector: row => row.created,
                sortable: true,
                grow: 1,
                wrap: true
            },
        ],

        rows: []
    });




    const dynamicTableRow = (tableData) => {
        const row = []
        // make table row
        if (tableData.rows) {
            let data = {}
            tableData.rows.forEach((ele, index) => {
                let entries = Object.entries(ele);
                entries.forEach((element) => {
                    data = {
                        ...data,
                        [element[0]]: ReactHtmlParser(element[1])
                    }
                });
                row.push(data)
            })
        }
        return row
    }

    const fetchUsers = (pageNo, limit) => {
        axios({
            method: 'POST',
            url: `${baseURL}fetch-admin-activity`,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.token },
            data: { pageno: pageNo, limit: limit }
        }).then(res => {
            if (res.data.status === true) {
                setDatatable({ ...datatable, rows: res.data.data })
                setLoader({ isLoading: false })
            }
        }).catch(error => {
            setLoader({ isLoading: false })
        })
    };

    const fetchDataLength = () => {
        axios({
            method: 'POST',
            url: `${baseURL}fetchdata`,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.token },
        }).then(res => {
            if (res.data.status === true) {
                setTotalRows(res.data.totaldata);
            }
        }).catch(error => {
            setLoader({ isLoading: false })
        })
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoader({ isLoading: true });
        fetchUsers(page, newPerPage)
        setPerPage(newPerPage);
    };

    const handlePageChange = page => {
        fetchUsers(page, perPage);
    };

    useEffect(() => {
        fetchUsers(1, perPage)
        fetchDataLength()
    }, []);


    return (
        <div>
            <div id="app">
                <div className="main-wrapper main-wrapper-1">
                    <div className="navbar-bg" />
                    {/* Main Content */}
                    <div className="main-content">
                        <section className="section">
                            <div className="section-header">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div className="section-header-breadcrumb-content">
                                            <h1>Activity Logs</h1>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header d-flex justify-content-between">
                                            <h4>Admin/Root Activity</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                < ReactTable
                                                    columns={datatable.columns}
                                                    data={dynamicTableRow(datatable)}
                                                    selectableRows
                                                    pagination
                                                    paginationServer
                                                    progressPending={loader.isLoading}
                                                    responsive={true}
                                                    highlightOnHover={true}
                                                    onChangePage={handlePageChange}
                                                    paginationTotalRows={totalRows}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </section>

                    </div>
                </div>
            </div>
        </div>
    )
};


export default ActivityLogs;
